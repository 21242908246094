<template>
  <div class="echart">
    <div class="bg"></div>
    <div class="echart_box">
      <el-tabs v-model="activeName">
        <el-tab-pane label="客户分析" name="first">
          <TabPaneTemp ref="paneTempFirst" :search="search" @searchBtn="searchBtn" @ShopType="ShopType"></TabPaneTemp>
          <div class="dataBox mt20 " style="height: 400px;">
            <div class="left Box">
              <div class="title"><i class="el-icon-user-solid"></i> 客户数量</div>
              <div class="trapezoid">
                <div class="trapezoidWarp">
                  <div class="position trapezoidLeft">
                    成交率
                    <span :class="{ 'color-red': CustomerVolume.isTop, 'color-green': !CustomerVolume.isTop }">{{
                      CustomerVolume.cj_rate }}
                      <!-- <el-icon v-show="!CustomerVolume.isTop" class="el-icon-bottom"></el-icon>
                      <el-icon v-show="CustomerVolume.isTop" class="el-icon-top"></el-icon> -->
                    </span>
                    <img src="../../assets/images/jiantou-left.png">
                  </div>
                  <div class="trapezoidItem1">档案量{{ CustomerVolume.customer_count }}</div>
                  <div class="trapezoidItem2">进店量{{ CustomerVolume.inshop_count }}</div>
                  <div class="trapezoidItem3">成交量{{ CustomerVolume.cj_count }}</div>
                  <div class="trapezoidItem4">保有量{{ CustomerVolume.by_count }}</div>
                  <div class="trapezoidItem5">*累计保有客户量</div>
                  <div class="position trapezoidRight1">
                    <img src="../../assets/images/jiantou-right.png">
                    进店率
                    <span :class="{ 'color-red': CustomerVolume.isTop, 'color-green': !CustomerVolume.isTop }">{{
                      CustomerVolume.inshop_rate }}
                      <!-- <el-icon v-show="!CustomerVolume.isTop" class="el-icon-bottom"></el-icon>
                      <el-icon v-show="CustomerVolume.isTop" class="el-icon-top"></el-icon> -->
                    </span>
                  </div>
                  <!-- <div class="position trapezoidRight2">
                    <img src="../../assets/images/jiantou-right1.png" style="width: 120px;"> &nbsp;
                    保有率
                    <span :class="{ 'color-red': CustomerVolume.isTop, 'color-green': !CustomerVolume.isTop }">{{
                      CustomerVolume.by_rate }}
                      <el-icon v-show="!CustomerVolume.isTop" class="el-icon-bottom"></el-icon>
                      <el-icon v-show="CustomerVolume.isTop" class="el-icon-top"></el-icon>
                    </span>

                  </div> -->
                </div>

              </div>
            </div>
            <div class="right Box">
              <div class="title"> 综合评分:</div>
              <div class="jb"><span>{{ CustomerVolume.comprehensive_score }}</span>级</div>
              <div class="num">客户累计保有数量: {{ CustomerVolume.by_count }}</div>
              <div class="jbList">
                <div>
                  <p class="tit"><span>A</span>级</p>
                  <p class="info">累计保有人数在200人以上</p>
                </div>
                <div>
                  <p class="tit"><span>B</span>级</p>
                  <p class="info">累计保有人数在150-200人</p>
                </div>
                <div>
                  <p class="tit"><span>C</span>级</p>
                  <p class="info">累计保有人数在150人以下</p>
                </div>
              </div>
            </div>
          </div>
          <div class="dataBox mt20" style="height: 360px; background-color: #fff; padding: 20px; border-radius: 4px;">
            <div class="echartsBox" id="jd_echart"
              style="width:50%; height: 350px; background-color: #f5f6f9;  padding: 10px; border-radius: 4px;"
              ref="chart1"></div>
            <div style="width:50%; height: 350px; padding: 10px;display: flex;align-items: center;">
              <el-radio-group v-model="online_store_entry" style="width: 80px;margin-left: 10px; line-height: 36px;">
                <el-radio :label="1">线上进店</el-radio>
                <el-radio :label="2">转介绍进店</el-radio>
                <el-radio :label="3">外联进店</el-radio>
              </el-radio-group>
              <div class="echartsBox" id="cj_echart" style="flex: 1; height: 350px; padding: 10px;" ref="chart2"></div>
            </div>
          </div>
          <div class="dataBox mt20" style="height: 360px; background-color: #fff; padding: 20px; border-radius: 4px;">
            <div class="echartsBox" id="jd_echart6"
              style="width: 50%; height: 350px; background-color: #f5f6f9;  padding: 10px; border-radius: 4px;"
              ref="chart3"></div>
            <div class="echartsBox" id="cj_echart7" style="width: 50%; height: 350px; padding: 10px;" ref="chart4">
            </div>
          </div>
          <div class="dataBox mt20" style="height: 360px; background-color: #fff; padding: 20px; border-radius: 4px;">
            <div class="echartsBox" id="jd_echart8"
              style="width: 50%; height: 350px; background-color: #f5f6f9;  padding: 10px; border-radius: 4px;"
              ref="chart5"></div>
            <div class="echartsBox" id="cj_echart9" style="width: 50%; height: 350px; padding: 10px;" ref="chart6">
            </div>
          </div>
          <div class="dataBox mt20" style=" background-color: #fff; padding: 20px; border-radius: 4px;">
            <div class="left Box" style="width: 50%;">
              <div class="title dataBox"><span><i class="el-icon-user-solid"></i> 客户价值</span> <span
                  style="text-align: right;color: #000;">*注:统计数据计算时间为 <span style="color: #0055ff;">一月</span>至 <span
                    style="color: #0055ff;">当月</span>业绩数据 </span></div>
              <div class="jbList" style="margin-top: 50px;">
                <div>
                  <p class="tit">销售总业绩</p>
                  <p class="info">
                    {{ CustomerValue.performance_total }}
                    <!-- 999999999.99 -->
                  </p>
                  <p class="dw">
                    (单位:元)
                  </p>
                </div>
                <div class="jbList_yeji_warp">
                  <div>
                    <p class="tit">成交数</p>
                    <p class="info">{{ CustomerValue.cj_count }}</p>
                    <p class="dw">
                      <!-- (成交新单金额>=500) -->
                      (单位:个)
                    </p>
                  </div>
                  <div>
                    <p class="tit">客户价值</p>
                    <p class="info">{{ CustomerValue.customer_price }}</p>
                    <p class="dw">
                      (单位:元)
                    </p>
                  </div>
                  <div>
                    <p class="tit">保有客户数</p>
                    <p class="info">{{ CustomerValue.by_customer_num }}</p>
                    <p class="dw">
                      <!-- (门店累计保有客户数) -->
                      (单位:个)
                    </p>
                  </div>
                  <div>
                    <p class="tit">绝对价值</p>
                    <p class="info">
                      {{ CustomerValue.abs_price }}

                    </p>
                    <p class="dw">
                      (单位:元)
                    </p>
                  </div>
                </div>

              </div>
            </div>
            <div class="right Box" style="width: 50%;">
              <div class="title"> 门店当前评级:</div>
              <div class="jb"><span>{{ CustomerValue.shop_level }}</span>级</div>
              <div class="jbList">
                <div>
                  <p class="tit"><span>A+</span>级</p>
                  <p class="info">2.5万元以上</p>
                </div>
                <div>
                  <p class="tit"><span>A</span>级</p>
                  <p class="info">2-2.5万元</p>
                </div>
                <div>
                  <p class="tit"><span>B</span>级</p>
                  <p class="info">1.5-2万元</p>
                </div>
                <div>
                  <p class="tit"><span>C</span>级</p>
                  <p class="info">1.2-1.5万元</p>
                </div>
                <div>
                  <p class="tit"><span>C-</span>级</p>
                  <p class="info">1.2万元以下</p>
                </div>
              </div>
            </div>
          </div>
          <div class=" mt20" style=" background-color: #fff; padding: 20px; border-radius: 4px;">
            <div style="height: 360px; background-color: #fff; padding: 20px; border-radius: 4px;">
              <div class="dataBox">
                <div class="echartsBox" id="jd_echart10"
                  style="width: 50%; height: 350px; background-color: #f5f6f9;  padding: 10px; border-radius: 4px;"
                  ref="chart7"></div>
                <div class="echartsBox" id="cj_echart11" style="width: 50%; height: 350px; padding: 10px;" ref="chart8">
                </div>
              </div>
            </div>
            <div style="height: 360px; background-color: #fff; padding: 20px; ">
              <div style="color: #0055ff; font-size: 18px;font-weight: 600;padding: 20px 0;">A客分析
                &nbsp;
                <span style="font-size: 14px;">客户管理方案&nbsp;&nbsp;</span>
                <el-switch v-model="aclass_switch" active-color="#13ce66" inactive-color="#E9967A"
                  @change=aclass_switch_open()>
                </el-switch>

              </div>
              <div class="dataBox mt20">
                <div class="echartsBox" id="jd_echart12"
                  style="width: 50%; height: 350px;   padding: 10px; border-radius: 4px;" ref="chart9"></div>
                <div class="echartsBox" id="cj_echart13" style="width: 50%; height: 350px; padding: 10px;"
                  ref="chart10">
                </div>
              </div>
            </div>
            <div style="height: 360px; background-color: #fff; padding: 20px; ">
              <div class="dataBox mt20" style="background-color: #f5f6f9; ">
                <div class="echartsBox" id="jd_echart14"
                  style="width: 50%; height: 350px;   padding: 10px; border-radius: 4px;" ref="chart11"></div>
                <div class="echartsBox" id="cj_echart15" style="width: 50%; height: 350px; padding: 10px;"
                  ref="chart12">
                </div>
              </div>
            </div>
            <div style="height: 360px; background-color: #fff; padding: 20px; ">
              <div class="dataBox mt20" style="background-color: #f5f6f9; ">
                <div class="echartsBox" id="jd_echart20"
                  style="width: 50%; height: 350px;   padding: 10px; border-radius: 4px;" ref="chart20"></div>
                <div class="echartsBox" id="cj_echart21" style="width: 50%; height: 350px; padding: 10px;"
                  ref="chart21">
                </div>
              </div>
            </div>
            <div class="mt40" style="width: 100%; box-sizing: border-box;" v-if="is_aclass_fa">
              <el-table class="no-border-table" :data="insert_tableData"
                :header-cell-style="{ borderColor: '#0055ff', color: '#0055ff' }" style="width: 100%">
                <el-table-column prop="customer_type" label="顾客分类" width="180">
                </el-table-column>
                <el-table-column prop="dingyi" label="定义" width="180">
                </el-table-column>
                <el-table-column prop="mudi" label="维护目的">
                </el-table-column>
                <el-table-column prop="xinli" label="A类顾客心理特征">
                </el-table-column>
                <el-table-column prop="celue" label="客户的销售策略有哪些">
                </el-table-column>
                <el-table-column prop="glfa" label="管理方案">
                </el-table-column>
              </el-table>
            </div>
            <div style="height: 360px; background-color: #fff; padding: 20px; ">
              <div style="color: #0055ff; font-size: 18px;font-weight: 600;padding: 20px 0;">B客分析
                &nbsp;
                <span style="font-size: 14px;">客户管理方案&nbsp;&nbsp;</span>
                <el-switch v-model="bclass_switch" active-color="#13ce66" inactive-color="#E9967A"
                  @change=bclass_switch_open()>
                </el-switch>
              </div>
              <div class="dataBox mt20">
                <div class="echartsBox" id="jd_echart16"
                  style="width: 50%; height: 350px;   padding: 10px; border-radius: 4px;" ref="chart13"></div>
                <div class="echartsBox" id="cj_echart17" style="width: 50%; height: 350px; padding: 10px;"
                  ref="chart14">
                </div>
              </div>
            </div>
            <div style="height: 360px; background-color: #fff; padding: 20px; ">
              <div class="dataBox mt20" style="background-color: #f5f6f9; ">
                <div class="echartsBox" id="jd_echart18"
                  style="width: 50%; height: 350px;   padding: 10px; border-radius: 4px;" ref="chart15"></div>
                <div class="echartsBox" id="cj_echart19" style="width: 50%; height: 350px; padding: 10px;"
                  ref="chart16">
                </div>
              </div>
            </div>
            <div style="height: 360px; background-color: #fff; padding: 20px; ">
              <div class="dataBox mt20" style="background-color: #f5f6f9; ">
                <div class="echartsBox" id="jd_echart22"
                  style="width: 50%; height: 350px;   padding: 10px; border-radius: 4px;" ref="chart22"></div>
                <div class="echartsBox" id="cj_echart23" style="width: 50%; height: 350px; padding: 10px;"
                  ref="chart23">
                </div>
              </div>
            </div>
            <div class="mt40" style="width: 100%; box-sizing: border-box;" v-if="is_bclass_fa">
              <el-table class="no-border-table" :data="insert1_tableData"
                :header-cell-style="{ borderColor: '#0055ff', color: '#0055ff' }" style="width: 100%">
                <el-table-column prop="customer_type" label="顾客分类" width="180">
                </el-table-column>
                <el-table-column prop="dingyi" label="定义" width="180">
                </el-table-column>
                <el-table-column prop="mudi" label="维护目的">
                </el-table-column>
                <el-table-column prop="xinli" label="B类顾客心理特征">
                </el-table-column>
                <el-table-column prop="celue" label="客户的销售策略有哪些">
                </el-table-column>
                <el-table-column prop="glfa" label="管理方案">
                </el-table-column>
              </el-table>
            </div>
          </div>
        </el-tab-pane>
        <!-- <el-tab-pane label="品项分析" name="second">
          <TabPaneTemp ref="TabPaneTemp" :search="search" @searchBtn="searchBtn" @ShopType="ShopType"></TabPaneTemp>

          <div class="mt20 " style="width: 100%; background-color: #fff;">
            <div style="width: 100%; padding: 20px;box-sizing: border-box;">
              <div class="title"><i class="el-icon-user-solid"></i> 品项分析</div>
              <div class="echartsBox" id="jd_echart20"
                style="width: 98%; height: 350px; background-color: #f5f6f9;  padding: 20px; border-radius: 4px;"
                ref="chart20"></div>
            </div>
            <div style="width: 100%; padding: 20px;box-sizing: border-box;">
              <div style="color: red; font-size: 18px; font-weight: 500;"> 品项类型 红榜 TOP10</div>
              <div class="tableLists">
                <div>
                  <p style="color: #acb1be;">医美项目</p>
                  <div class="plborder">
                    <el-table class="no-border-table" :data="tableData"
                      :header-cell-style="{ background: '#f5f6f9', color: '#5c6580' }" style="width: 100%">
                      <el-table-column prop="date" label="图片" width="100">
                      </el-table-column>
                      <el-table-column prop="name" label="卡项名称">
                      </el-table-column>
                      <el-table-column prop="address" label="数量" width="50">
                      </el-table-column>
                      <el-table-column prop="address" label="人次" width="50">
                      </el-table-column>
                    </el-table>
                  </div>
                </div>
                <div>
                  <p style="color: #acb1be;">皮肤项目</p>
                  <div class="plborder">
                    <el-table class="no-border-table" :data="tableData"
                      :header-cell-style="{ background: '#f5f6f9', color: '#5c6580' }" style="width: 100%">
                      <el-table-column prop="date" label="图片" width="100">
                      </el-table-column>
                      <el-table-column prop="name" label="卡项名称">
                      </el-table-column>
                      <el-table-column prop="address" label="数量" width="50">
                      </el-table-column>
                      <el-table-column prop="address" label="人次" width="50">
                      </el-table-column>
                    </el-table>
                  </div>
                </div>
                <div>
                  <p style="color: #acb1be;">护肤品</p>
                  <el-table class="no-border-table" :data="tableData"
                    :header-cell-style="{ background: '#f5f6f9', color: '#5c6580' }" style="width: 100%">
                    <el-table-column prop="date" label="图片" width="100">
                    </el-table-column>
                    <el-table-column prop="name" label="卡项名称">
                    </el-table-column>
                    <el-table-column prop="address" label="数量" width="50">
                    </el-table-column>
                    <el-table-column prop="address" label="人次" width="50">
                    </el-table-column>
                  </el-table>
                </div>
              </div>

            </div>
            <div style="width: 100%; padding: 20px;box-sizing: border-box;">
              <div style="color: #000; font-size: 18px; font-weight: 500;"> 品项类型 黑榜 TOP5</div>
              <div class="tableLists">
                <div>
                  <p style="color: #acb1be;">医美项目</p>
                  <div class="plborder">
                    <el-table class="no-border-table" :data="tableData"
                      :header-cell-style="{ background: '#f5f6f9', color: '#5c6580' }" style="width: 100%">
                      <el-table-column prop="date" label="图片" width="100">
                      </el-table-column>
                      <el-table-column prop="name" label="卡项名称">
                      </el-table-column>
                      <el-table-column prop="address" label="数量" width="50">
                      </el-table-column>
                      <el-table-column prop="address" label="人次" width="50">
                      </el-table-column>
                    </el-table>
                  </div>
                </div>
                <div>
                  <p style="color: #acb1be;">皮肤项目</p>
                  <div class="plborder">
                    <el-table class="no-border-table" :data="tableData"
                      :header-cell-style="{ background: '#f5f6f9', color: '#5c6580' }" style="width: 100%">
                      <el-table-column prop="date" label="图片" width="100">
                      </el-table-column>
                      <el-table-column prop="name" label="卡项名称">
                      </el-table-column>
                      <el-table-column prop="address" label="数量" width="50">
                      </el-table-column>
                      <el-table-column prop="address" label="人次" width="50">
                      </el-table-column>
                    </el-table>
                  </div>
                </div>
                <div>
                  <p style="color: #acb1be;">护肤品</p>
                  <el-table class="no-border-table" :data="tableData"
                    :header-cell-style="{ background: '#f5f6f9', color: '#5c6580' }" style="width: 100%">
                    <el-table-column prop="date" label="图片" width="100">
                    </el-table-column>
                    <el-table-column prop="name" label="卡项名称">
                    </el-table-column>
                    <el-table-column prop="address" label="数量" width="50">
                    </el-table-column>
                    <el-table-column prop="address" label="人次" width="50">
                    </el-table-column>
                  </el-table>
                </div>
              </div>

            </div>
          </div>
        </el-tab-pane> -->
        <el-tab-pane label="员工分析" name="third">
          <TabPaneTemp ref="paneTempThird" :search="search" @searchBtn="searchBtn" @ShopType="ShopType" @setSearch="setSearch"></TabPaneTemp>

          <div class="mt20 " style="width: 100%; background-color: #fff;">
            <div style="width: 100%; padding: 20px;box-sizing: border-box;">
              <div class="title"><i class="el-icon-user-solid"></i> 员工分析</div>
              <div class="mt20"
                style="text-align: center;border: 1px solid #5c6580; padding: 20px; border-radius: 4px;">
                <el-row :gutter="20">
                  <el-col :span="4">
                    <div class="grid-content borr bg-purple">
                      <p style="font-size: 18px; font-weight: 500; color: #5c6580;">成交客户数</p>
                      <p style="font-size: 40px; font-weight: 700;margin: 5px 0;color: #5c6580;">
                        {{ PersonInfo.customer_cj_count }}</p>
                    </div>
                  </el-col>
                  <el-col :span="4">
                    <div class="grid-content borr bg-purple">
                      <p style="font-size: 18px; font-weight: 500; color: #5c6580;">累计保有客户</p>
                      <p style="font-size: 40px; font-weight: 700;margin: 5px 0;color: #5c6580;">
                        {{ PersonInfo.customer_by }}</p>
                    </div>
                  </el-col>
                  <el-col :span="4">
                    <div class="grid-content borr bg-purple">
                      <p style="font-size: 18px; font-weight: 500; color: #5c6580;">累计流失客户</p>
                      <p style="font-size: 40px; font-weight: 700;margin: 5px 0;color: #5c6580;">
                        {{ PersonInfo.customer_ls }}</p>
                    </div>
                  </el-col>
                  <el-col :span="4">
                    <div class="grid-content borr bg-purple">
                      <p style="font-size: 18px; font-weight: 500; color: #5c6580;">日均护理量</p>
                      <p style="font-size: 40px; font-weight: 700;margin: 5px 0;color: #5c6580;">
                        {{ PersonInfo.care_amount_dayavg }}</p>
                    </div>
                  </el-col>
                  <el-col :span="4">
                    <div class="grid-content borr bg-purple">
                      <p style="font-size: 18px; font-weight: 500; color: #5c6580;">日均客户数</p>
                      <p style="font-size: 40px; font-weight: 700;margin: 5px 0;color: #5c6580;">
                        {{ PersonInfo.care_customer_dayavg }}</p>
                    </div>
                  </el-col>
                  <el-col :span="4">
                    <div class="grid-content bg-purple">
                      <p style="font-size: 18px; font-weight: 500; color: #5c6580;">补单业绩</p>
                      <p style="font-size: 34px; font-weight: 700;margin: 5px 0px 0px -10px;color: #5c6580;">
                        {{ PersonInfo.replenishment }}
                      </p>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="mt40" style="width: 100%; box-sizing: border-box;">
                <el-table class="no-border-table" :data="customer_tableData"
                  :header-cell-style="{ borderColor: '#0055ff', color: '#0055ff' }" style="width: 100%">
                  <el-table-column prop="person_name" label="姓名" width="90">
                  </el-table-column>
                  <el-table-column prop="customer_count" label="分配客户数">
                  </el-table-column>
                  <el-table-column prop="wl_customer_num" label="外联客户数">
                  </el-table-column>
                  <el-table-column prop="zjs_customer_num" label="转介绍客户数">
                  </el-table-column>
                  <el-table-column prop="ya_num" label="申请优案客户数">
                  </el-table-column>
                  <el-table-column prop="customer_by" label="累计保有客户">
                  </el-table-column>
                  <el-table-column prop="customer_ls" label="累计流失客户">
                  </el-table-column>
                  <el-table-column prop="care_amount_dayavg" label="日均护理量">
                  </el-table-column>
                  <el-table-column prop="care_customer_dayavg" label="日均护理人数">
                  </el-table-column>
                  <el-table-column prop="replenishment" label="补单业绩">
                  </el-table-column>
                  <el-table-column prop="performance" label="总业绩">
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>
          <div class="mt20" style="width: 100%; background-color: #fff;">
            <div style="width: 100%; padding: 20px;box-sizing: border-box;">
              <p>员工总业绩</p>
              <div class="echartsBox" id="jd_echart21"
                style="width: 98%; height: 350px; background-color: #f5f6f9;  padding: 20px; border-radius: 4px;"
                ref="thirdChart1"></div>
              <p>护理量总数与日均护理量和日均护理人头数</p>
              <div class="echartsBox" id="jd_echart22"
                style="width: 98%; height: 350px; background-color: #f5f6f9;  padding: 20px; border-radius: 4px;"
                ref="thirdChart2"></div>

            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import {
  readAdminSysHomeInfo,
  readAdminSysHomePersonAchieve,
} from "@/api/sys";
import {
  getPersonInfo,
  getPersonData,
  getEnterShopCount,
  getEnterShopClassCount,
  getBargain,
  getShopRetainCustomers,
  getPeriodicityBaoyou,
  getCustomerValue,
  getAbcdCustomerMd,
  getYearConsumptionData,
  getACustomerAnalyse,
  getBCustomerAnalyse,
  getPyramidInfo,//倒三角
  getACustomerSynthesis,//A类集合
  getBCustomerSynthesis,//B类集合
} from "@/api/newBoard"
import { mapGetters } from "vuex";
import TabPaneTemp from './tab-pane-temp.vue';
class DataReveal {
  revealList = [
    {
      name: "当前业绩(元)",
      icon: require("@/assets/images/index_1.png"),
      num: 0
    },
    {
      name: "完成新单",
      icon: require("@/assets/images/index_2.png"),
      num: 0
    },
    {
      name: "完成补单",
      icon: require("@/assets/images/index_3.png"),
      num: 0
    },
    {
      name: "新单成交率(%)",
      icon: require("@/assets/images/index_4.png"),
      num: 0
    },
    {
      name: "成交个数",
      icon: require("@/assets/images/index_5.png"),
      num: 0
    },
    {
      name: "完成进店",
      icon: require("@/assets/images/index_6.png"),
      num: 0
    },
    {
      name: "网络进店率(%)",
      icon: require("@/assets/images/index_7.png"),
      num: 0
    },
    {
      name: "转介绍个数",
      icon: require("@/assets/images/index_8.png"),
      num: 0
    }
  ];

  revealList1 = [
    {
      name: "会员列表",
      icon: "",
      link: "客户列表"
    },
    {
      name: "门店信息",
      icon: "",
      link: "店铺列表"
    },
    {
      name: "订单模块",
      icon: "",
      link: "销售出库单"
    },
    {
      name: "数据分析",
      icon: "",
      link: "门店线上导客"
    },
    {
      name: "商品列表",
      icon: "",
      link: "商品列表"
    },
    {
      name: "员工列表",
      icon: "",
      link: "员工列表"
    },
    {
      name: "优秀案例",
      icon: "",
      link: "案例列表"
    },
    {
      name: "回访列表",
      icon: "",
      link: "客保列表"
    }
  ];
  revealList2 = [
    {
      name: "成交客户数",
      icon: require("@/assets/images/info_1.png"),
      link: "新单列表",
      num: 0
    },
    {
      name: "保有客户数",
      icon: require("@/assets/images/info_6.png"),
      link: "保有顾客",
      num: 0
    },
    {
      name: "流失客户数",
      icon: require("@/assets/images/info_2.png"),
      link: "流失顾客",
      num: 0
    },
    {
      name: "流失预警数",
      icon: require("@/assets/images/info_3.png"),
      link: "流失预警",
      num: 0
    },
    {
      name: "保有率",
      icon: require("@/assets/images/info_5.png"),
      link: "",
      num: 0
    },
    {
      name: "当月预警回流率",
      icon: require("@/assets/images/info_5.png"),
      link: "业务分析报表",
      num: 0
    },

    // {
    //   name: "回流率",
    //   icon: require("@/assets/images/info_4.png"),
    //   link: "",
    //   num: 0
    // }
  ];
  revealList3 = [];
}

export default {
  name: "echart",
  components: {
    TabPaneTemp
  },
  data() {
    return {
      search: {
        shop_type: '2', // 2是门店 1是门诊
        market_id: '', // 市场id
        shop_id: '', // 门店id
        time_type: 1, //  1 当天  2 近7天  3 月度  4 年度
        start_timetart_time: '', // 时间段搜索-开始时间
        end_time: ''// 时间段搜索-结束时间
      }, // 检索条件
      dataReveal: new DataReveal(), // 数据展示
      value1: "", //时间范围
      isShop: false,
      shop: false,
      activeName: 'first',
      value: '',
      value1: '',
      value2: '',
      radio: 3,
      options: [],
      tableData: [],
      aclass_switch: false,
      is_aclass_fa: false,
      bclass_switch: false,
      is_bclass_fa: false,
      insert_tableData: [
        {
          'customer_type': 'A类',
          'dingyi': '年累计消费5万以上的顾客是主要的价值型客户。',
          'mudi': '通过对A类客户的管理，让A类客户长久的留存，并实现合理循环性消费，从而实现A类客户的价值贡献',
          'xinli': '1、希望得到VIP待遇、满足自己的虚荣心等2、对于这类客户要足够满足顾客的情感需求和个性化需求',
          'celue': '1、利用VIP特权，进行优惠的设置。2.利用大型活动进行综合性的方案设计。高端项目为主，基础项目为辅。3、阶段性销售，而不是月月销售',
          'glfa': '1、做好顾客的信息管理，时常更新顾客信息。了解顾客的喜好和生活状态的变化。2、做好顾客的情感链接。店长一定要和A类客户保持亲密度。做好A类顾客的服务管理，匹配的护理师、日常的沟通方式、需要哪些增值服务等等',
        }
      ],
      insert1_tableData: [
        {
          'customer_type': 'B类',
          'dingyi': '年累计消费2万以上的顾客，是主要的价值型客户，也是主要的潜在高价值客户群体',
          'mudi': '通过对B类客户的管理，让B类客户长久的留存，并实现合理循环性消费，争取晋级为A类客户，实现更稳定和更长久的价值贡献',
          'xinli': '1、B类客户喜欢实惠消费；2、这类客户中有一部分有消费实力但还是观望店面的服务等各方面的匹配程度，还有一部分虽然预算有限，当仍然期待得到重视',
          'celue': '1、利用活动设计，合理提升消费额。2、基础项目普及，高端项目为辅，逐步提升消费格局',
          'glfa': '1、做好顾客的信息管理，时常更新顾客信息，了解顾客的喜好和生活状态的变化。2、做好顾客的情感链接。利用特殊日子制造仪式感。3、做好顾客的消费管理。利用活动合理提升客户消费额，目标为晋级A客或稳定在B客行列',

        }
      ],
      customer_tableData: [],
      boardGetPyramidData: {},
      getAbcdCustomerData: {},
      getBCustomerAnalyseData: {},
      PersonInfo: {},//员工分析
      CustomerValue: {},//客户价值
      online_store_entry: 1,
      CustomerVolume: {}

    };
  },
  computed: {
    ...mapGetters(["userInfo"])
  },
  watch: {
    online_store_entry: {
      handler(val) {
        this.enterShopCountMethod()
      }
    },
    activeName: {
      handler(val) {
        if (val == 'first') {
          this.search = {
            ...this.search,
            market_id: '', // 市场id
            shop_id: '', // 门店id
            time_type: 1, //  1 当天  2 近7天  3 月度  4 年度
            start_timetart_time: '', // 时间段搜索-开始时间
            end_time: ''// 时间段搜索-结束时间
          };

          this.$refs.paneTempFirst.getShopDataInfo();
          this.getFirstDataS();

        } else if (val == 'second') {
          this.getSecondDataS();
        } else {
          this.search = {
            ...this.search,
            market_id: '', // 市场id
            shop_id: '', // 门店id
            time_type: 1, //  1 当天  2 近7天  3 月度  4 年度
            start_timetart_time: '', // 时间段搜索-开始时间
            end_time: ''// 时间段搜索-结束时间
          };
          this.$refs.paneTempThird.getShopDataInfo();

        }
      },
    },
  },
  mounted() {
    this.getFirstDataS()
    this.$refs.paneTempFirst.getShopDataInfo();
  },
  methods: {
    aclass_switch_open() {
      if (this.aclass_switch) {
        this.is_aclass_fa = true;
      } else {
        this.is_aclass_fa = false;
      }
    },
    bclass_switch_open() {
      if (this.bclass_switch) {
        this.is_bclass_fa = true;
      } else {
        this.is_bclass_fa = false;
      }
    },
    ShopType(value) {
      this.searchz.shop_type = value;
    },
    setSearch({ShopList,MarketList}) {
      this.search.market_id = MarketList[0].value;
      this.search.shop_id = ShopList[0].value;
      this.getThirdDataS();
    },
    searchBtn({search_dateTime}) {
      if (search_dateTime.length > 0) {
        this.search.start_time = search_dateTime[0] || ''
        this.search.end_time = search_dateTime[1] || ''
      }
      if (this.activeName == 'first') {
        this.getFirstDataS()
      } else if (this.activeName == 'second') {
        this.getSecondDataS()
      } else if (this.activeName == 'third') {
        this.getThirdDataS()
      }
    },
    //第一页接口
    getFirstDataS() {
      this.getPyramidInfoMethod()
      this.getEnterShopCountMethod()
      this.enterShopCountMethod()
      this.getBargainMethod()
      this.getShopRetainCustomersMethod()
      this.getPeriodicityBaoyouMethod()
      this.getCustomerValueMethod()
      this.getAbcdCustomerMethod()
      this.getYearConsumptionDataMethod()
      this.getACustomerAnalyseMethod()
      this.getACustomerSynthesisMethod()
      this.getBCustomerAnalyseMethod()
      this.getBCustomerSynthesisMethod()
    },
    //第二页接口
    getSecondDataS() {

    },
    //第三页接口
    getThirdDataS() {
      this.getPersonInfoMethod()
      this.getPersonDataMethod()
    },
    getPyramidInfoMethod() {
      getPyramidInfo(this.search).then(res => {
        this.CustomerVolume = res.data
      });
    },


    getPersonInfoMethod() {
      console.log('请求时的搜索条件')
      console.log(this.search)
      getPersonInfo(this.search).then(res => {
        if (res.code == 200) {
          this.PersonInfo = res.data
        } else {
          this.$message({
            type: "error",
            message: res.msg
          });
        }
      });
    },
    getPersonDataMethod() {
      getPersonData(this.search).then(res => {
        if (res.code == 200) {
          this.customer_tableData = res.data.list
          this.getThirdChartData(res.data.hl)
        } else {
          this.$message({
            type: "error",
            message: res.msg
          });
        }
      });
    },
    getEnterShopCountMethod() {
      getEnterShopCount(this.search).then(res => {
        if (res.code == 200) {
          let myChart = echarts.init(this.$refs.chart1);
          var titleText = '{a|指标一:}{b|进店数量}{c|同期数据对比分析}';
          var xAxisData = res.data.x;
          var seriesData = [
            {
              name: res.data.year[0],
              type: 'bar',
              itemStyle: {
                color:'#3BC7DB'
              },
              data: res.data.one,
              label: {
                show: true,
                position: 'top'
              }
            },
            {
              name: res.data.year[1],
              type: 'bar',
              itemStyle: {
                 color:'#FF017E'
              },
              data: res.data.two,
              label: {
                show: true,
                position: 'top'
              }
            }
          ]
          var option = this.echartsMethod(titleText, xAxisData, seriesData, "渠道")
          myChart.setOption(option)
        } else {
          this.$message({
            type: "error",
            message: res.msg
          });
        }
      });
    },
    //网络进店分析
    enterShopCountMethod() {
      getEnterShopClassCount({ ...this.search, type: this.online_store_entry }).then(res => {
        if (res.code == 200) {
          let myChart = echarts.init(this.$refs.chart2);

          var titleText = [
            '{b|疤痕/痘痘/皮肤 同期进店类型数据分析}',
            ''
          ].join('\n');
          var xAxisData = res.data.x;
          var seriesData = [
            {
              name: res.data.year[0],
              type: 'bar',
              itemStyle: {
                color:'#3BC7DB'
              },
              data: res.data.one,
              label: {
                show: true,
                position: 'top'
              }
            },
            {
              name: res.data.year[1],
              type: 'bar',
              itemStyle: {
                 color:'#FF017E'
              },
              data: res.data.two,
              label: {
                show: true,
                position: 'top'
              }
            }
          ]
          var option = this.echartsMethod(titleText, xAxisData, seriesData, "类型")
          myChart.setOption(option)
        } else {
          this.$message({
            type: "error",
            message: res.msg
          });
        }
      });
    },
    //成交率/成交率
    getBargainMethod() {
      getBargain(this.search).then(res => {
        if (res.code == 200) {
          let myChart = echarts.init(this.$refs.chart3);
          let myChart4 = echarts.init(this.$refs.chart4);
          var titleText = [
            '{a|指标二:}{b|成交个数}{c|同期数据对比分析}',
            ''
          ].join('\n');
          var titleText2 = [
            '{b|成交率:}{c|同期数据分析}',
            ''
          ].join('\n');
          var xAxisData = res.data.x;
          var seriesData = [
            {
              name: res.data.year[0],
              type: 'bar',
              itemStyle: {
                color:'#3BC7DB'
              },
              data: res.data.cj_one,
              label: {
                show: true,
                position: 'top'
              }
            },
            {
              name: res.data.year[1],
              type: 'bar',
              itemStyle: {
                 color:'#FF017E'
              },
              data: res.data.cj_two,
              label: {
                show: true,
                position: 'top'
              }
            }
          ]
          var seriesData2 = [
            {
              name: res.data.year[0],
              type: 'line',
              itemStyle: {
                color:'#3BC7DB'
              },
              data: res.data.cj_one_rate,
              label: {
                show: true,
                position: 'top'
              }
            },
            {
              name: res.data.year[1],
              type: 'line',
              itemStyle: {
                 color:'#FF017E'
              },
              data: res.data.cj_two_rate,
              label: {
                show: true,
                position: 'top'
              }
            }
          ]
          var option = this.echartsMethod(titleText, xAxisData, seriesData, "月份")
          var option4 = this.echartsMethod(titleText2, xAxisData, seriesData2, "月份")
          myChart.setOption(option)
          myChart4.setOption(option4)
        } else {
          this.$message({
            type: "error",
            message: res.msg
          });
        }
      });
    },
    getShopRetainCustomersMethod() {
      getShopRetainCustomers(this.search).then(res => {
        if (res.code == 200) {
          let myChart = echarts.init(this.$refs.chart5);
          var titleText = [
            '{a|指标三:}{b|累计保有客户数}',
            ''
          ].join('\n');

          var xAxisData = res.data.x;
          var seriesData = [
            {
              name: res.data.year[0],
              type: 'line',
              itemStyle: {
                color:'#3BC7DB'
              },
              data: res.data.lj_by_one,
              label: {
                show: true,
                position: 'top'
              }
            }, {
              name: res.data.year[1],
              type: 'line',
              itemStyle: {
                 color:'#FF017E'
              },
              data: res.data.last_lj_by_one,
              label: {
                show: true,
                position: 'top'
              }
            }
          ]
          var option = this.echartsMethod(titleText, xAxisData, seriesData, "月份")
          myChart.setOption(option)
        } else {
          this.$message({
            type: "error",
            message: res.msg
          });
        }
      });
    },
    getPeriodicityBaoyouMethod() {
      getPeriodicityBaoyou(this.search).then(res => {
        if (res.code == 200) {
          let myChart = echarts.init(this.$refs.chart6);
          var titleText = [
            '{b|周期性保有数}',
            ''
          ].join('\n');
          var xAxisData = res.data.x;
          var seriesData = [
            {
              name: res.data.year[0],
              type: 'line',
              itemStyle: {
                color:'#3BC7DB'
              },
              data: res.data.zqx_by_one,
              label: {
                show: true,
                position: 'top'
              }
            },
            {
              name: res.data.year[1],
              type: 'line',
              itemStyle: {
                 color:'#FF017E'
              },
              data: res.data.last_zqx_by_one,
              label: {
                show: true,
                position: 'top'
              }
            }
          ]
          var option = this.echartsMethod(titleText, xAxisData, seriesData, "月份")
          myChart.setOption(option)
        } else {
          this.$message({
            type: "error",
            message: res.msg
          });
        }
      });
    },
    getCustomerValueMethod() {
      getCustomerValue(this.search).then(res => {
        if (res.code == 200) {
          this.CustomerValue = res.data
        } else {
          this.$message({
            type: "error",
            message: res.msg
          });
        }
      })
    },
    getAbcdCustomerMethod() {
      getAbcdCustomerMd(this.search).then(res => {
        if (res.code == 200) {
          let myChart = echarts.init(this.$refs.chart7);
          myChart.setOption({
            title: {
              width: 500,
              text: [
                '{a|门店累计保有客户 }{b|占比分布图:}',
                ''
              ].join('\n'),
              textStyle: {
                rich: {
                  a: {
                    color: '#5c6580',
                    fontSize: 16
                  },
                  b: {
                    color: '#5c6580',
                    fontSize: 16
                  },
                }
              }
            },
            tooltip: {
              trigger: 'item'
            },
            graphic: [{
              type: 'text',//控制内容为文本文字
              left: 'center',
              top: '150px',//调整距离盒子高处的位置
              style: {
                fill: '#5c6580',//控制字体颜色
                text: 'A、B、C、D',//控制第一行字显示内容
                fontSize: '16px',
              }
            }, {
              type: 'text',
              left: 'center',
              top: '170px',
              z: 10,
              style: {
                text: '各个类型客户',
                font: 'Microsoft YaHei',
                fontSize: '16px',
                lineHeight: 15,
                fill: '#5c6580',
              }
            }, {
              type: 'text',
              left: 'center',
              top: '190px',
              z: 10,
              style: {
                text: '总数及占比',
                font: 'Microsoft YaHei',
                fontSize: '16px',
                lineHeight: 15,
                fill: '#5c6580',
              }
            }],
            series:
            {
              name: '客户类型',
              type: 'pie',
              radius: ['50%', '70%'],
              labelLine: {
                show: false
              },
              data: res.data,
              avoidLabelOverlap: false,
              padAngle: 5,
              itemStyle: {
                borderRadius: 2,
                borderColor: '#fff',
                borderWidth: 4
              },
              label: {
                show: true,
                position: 'outer',
                color: '#333333',
                formatter: '{b}数量:{c}  占比:{d}%',
                emphasis: {//中间文字显示
                  show: true,
                }
              },
              labelLine: {
                lineStyle: {
                  color: '#333'
                }
              },
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          })
        } else {
          this.$message({
            type: "error",
            message: res.msg
          });
        }
      })
    },
    getYearConsumptionDataMethod() {
      getYearConsumptionData(this.search).then(res => {
        if (res.code == 200) {
          let myChart = echarts.init(this.$refs.chart8);
          var titleText = [
            '{a|年度客户消费金额:}{b|数据统计分布图}',
            ''
          ].join('\n');
          var xAxisData = res.data.x;
          var seriesData = [
            {
              name: res.data.title,
              type: 'bar',
              barWidth: '50%',
              data: res.data.consumption,
              label: {
                show: true,
                position: 'top'
              },
              itemStyle: {
                color:'#3BC7DB'
              }
            }
          ]
          var option = this.echartsMethod(titleText, xAxisData, seriesData, "消费区间", ['#0055ff'])
          myChart.setOption(option)
        } else {
          this.$message({
            type: "error",
            message: res.msg
          });
        }
      });
    },
    getACustomerAnalyseMethod() {
      getACustomerAnalyse(this.search).then(res => {
        if (res.code == 200) {
          let myChart = echarts.init(this.$refs.chart9);
          var titleText = [
            '{a|A类客户:}{b|价值分析}',
            ''
          ].join('\n');
          var xAxisData = res.data.x;
          var seriesData = [
            {
              name: res.data.title,
              type: 'bar',
              barWidth: '50%',
              data: res.data.consumption,
              label: {
                show: true,
                position: 'top'
              },
              itemStyle: {
                color:'#3BC7DB'
              }
            }
          ]
          var option = this.echartsMethod(titleText, xAxisData, seriesData, "消费区间", ['#0055ff'])
          myChart.setOption(option)
        } else {
          this.$message({
            type: "error",
            message: res.msg
          });
        }
      });
    },
    //A集合
    getACustomerSynthesisMethod() {
      getACustomerSynthesis(this.search).then(res => {
        if (res.code == 200) {
          this.getACustomerByRateMethod(res.data);
          this.getACustomerByMethod(res.data);
          this.getACustomerLsMethod(res.data);
          this.getACustomerZqxByMethod(res.data);
          this.getACustomerZqxLsMethod(res.data);

        } else {
          this.$message({
            type: "error",
            message: res.msg
          });
        }
      });
    },
    getACustomerByRateMethod(data) {
      let myChart = echarts.init(this.$refs.chart10);
      var titleText = [
        '{a|A类客户:}{b|保有率}',
        ''
      ].join('\n');
      var xAxisData = data.x;
      var seriesData = [
        {
          name: data.title[1],
          type: 'line',
          data: data.lj_by_rate,
          label: {
            show: true,
            position: 'top'
          },
          lineStyle:{
            color:'#3BC7DB',
            width: 3
          },
          itemStyle: {
            color:'#3BC7DB'
          }
        },
        {
          name: data.title[0],
          type: 'line',
          data: data.zqx_by_rate,
          label: {
            show: true,
            position: 'top'
          },
          lineStyle:{
            color:'#FF017E',
            width: 3
          },
          itemStyle: {
            color:'#FF017E'
          }
        }
      ]
      var option = this.echartsMethod(titleText, xAxisData, seriesData, "月份", ['#0055ff', '#e57a08', '#ffde00'])
      myChart.setOption(option)
    },
    getACustomerByMethod(data) {
      let myChart = echarts.init(this.$refs.chart11);
      var titleText = [
        '{a|A类客户:}{b|累计保有人数分析}',
        ''
      ].join('\n');
      var xAxisData = data.x;
      var seriesData = [
        {
          name: data.title1[0],
          type: 'bar',
          data: data.lj_by,
          label: {
            show: true,
            position: 'top'
          },
          itemStyle: {
            color:'#3BC7DB'
          }
        },
        {
          name: data.title1[1],
          type: 'bar',
          data: data.last_lj_by,
          label: {
            show: true,
            position: 'top'
          },
          itemStyle: {
            color:'#FF017E'
          }
        }
      ]
      var option = this.echartsMethod(titleText, xAxisData, seriesData, "月份", ['#ff007e', '#ff88b9'])
      myChart.setOption(option)
    },
    getACustomerLsMethod(data) {
      let myChart = echarts.init(this.$refs.chart12);
      var titleText = [
        '{a|A类客户:}{b|累计流失人数分析}',
        ''
      ].join('\n');
      var xAxisData = data.x;
      var seriesData = [
        {
          name: data.title2[0],
          type: 'bar',
          data: data.lj_ls,
          label: {
            show: true,
            position: 'top'
          },
          itemStyle: {
            color:'#3BC7DB'
          }
        },
        {
          name: data.title2[1],
          type: 'bar',
          data: data.last_lj_ls,
          label: {
            show: true,
            position: 'top'
          },
          itemStyle: {
            color:'#FF017E'
          }
        }
      ]
      var option = this.echartsMethod(titleText, xAxisData, seriesData, "月份", ['#00c2a0', '#a9f2ba'])
      myChart.setOption(option)
    },
    getACustomerZqxByMethod(data) {
      let myChart = echarts.init(this.$refs.chart20);
      var titleText = [
        '{a|A类客户:}{b|周期性保有人数分析}',
        ''
      ].join('\n');
      var xAxisData = data.x;
      var seriesData = [
        {
          name: data.title1[0],
          type: 'bar',
          data: data.zqx_by,
          label: {
            show: true,
            position: 'top'
          },
          itemStyle: {
            color:'#3BC7DB'
          }
        },
        {
          name: data.title1[1],
          type: 'bar',
          data: data.last_zqx_by,
          label: {
            show: true,
            position: 'top'
          },
          itemStyle: {
            color:'#FF017E'
          }
        }
      ]
      var option = this.echartsMethod(titleText, xAxisData, seriesData, "月份", ['#ff007e', '#ff88b9'])
      myChart.setOption(option)
    },
    getACustomerZqxLsMethod(data) {
      let myChart = echarts.init(this.$refs.chart21);
      var titleText = [
        '{a|A类客户:}{b|周期性流失人数分析}',
        ''
      ].join('\n');
      var xAxisData = data.x;
      var seriesData = [
        {
          name: data.title2[0],
          type: 'bar',
          data: data.zqx_ls,
          label: {
            show: true,
            position: 'top'
          },
          itemStyle: {
            color:'#3BC7DB'
          }
        },
        {
          name: data.title2[1],
          type: 'bar',
          data: data.last_zqx_ls,
          label: {
            show: true,
            position: 'top'
          },
          itemStyle: {
            color:'#FF017E'
          }
        }
      ]
      var option = this.echartsMethod(titleText, xAxisData, seriesData, "月份", ['#00c2a0', '#a9f2ba'])
      myChart.setOption(option)
    },
    getBCustomerAnalyseMethod() {
      getBCustomerAnalyse(this.search).then(res => {
        if (res.code == 200) {
          let myChart = echarts.init(this.$refs.chart13);
          var titleText = [
            '{a|B类客户:}{b|价值分析}',
            ''
          ].join('\n');
          var xAxisData = res.data.x;
          var seriesData = [
            {
              name: res.data.title,
              type: 'bar',
              barWidth: '50%',
              data: res.data.consumption,
              label: {
                show: true,
                position: 'top'
              },
              itemStyle: {
                color:'#3BC7DB'
              }
            }
          ]
          var option = this.echartsMethod(titleText, xAxisData, seriesData, "消费区间", ['#0055ff'])
          myChart.setOption(option)
        } else {
          this.$message({
            type: "error",
            message: res.msg
          });
        }
      });
    },

    //B集合
    getBCustomerSynthesisMethod() {
      getBCustomerSynthesis(this.search).then(res => {
        if (res.code == 200) {
          this.getBCustomerByRateMethod(res.data);
          this.getBCustomerByMethod(res.data);
          this.getBCustomerLsMethod(res.data);
          this.getBCustomerZqxByMethod(res.data);
          this.getBCustomerZqxLsMethod(res.data);
        } else {
          this.$message({
            type: "error",
            message: res.msg
          });
        }
      });
    },
    getBCustomerByRateMethod(data) {

      let myChart = echarts.init(this.$refs.chart14);
      var titleText = [
        '{a|B类客户:}{b|保有率}',
        ''
      ].join('\n');
      var xAxisData = data.x;
      var seriesData = [
        {
          name: data.title[1],
          type: 'line',
          data: data.lj_by_rate,
          label: {
            show: true,
            position: 'top'
          },
          lineStyle:{
            color:'#3BC7DB',
            width: 3
          },
          itemStyle: {
            color:'#3BC7DB'
          }
        },
        {
          name: data.title[0],
          type: 'line',
          data: data.zqx_by_rate,
          label: {
            show: true,
            position: 'top'
          },
          lineStyle:{
            color:'#FF017E',
            width: 3
          },
          itemStyle: {
            color:'#FF017E'
          }
        }
      ]
      var option = this.echartsMethod(titleText, xAxisData, seriesData, "月份", ['#0055ff', '#e57a08', '#ffde00'])
      myChart.setOption(option)
    },
    getBCustomerByMethod(data) {

      let myChart = echarts.init(this.$refs.chart15);
      var titleText = [
        '{a|B类客户:}{b|累计保有人数分析}',
        ''
      ].join('\n');
      var xAxisData = data.x;
      var seriesData = [
        {
          name: data.title1[0],
          type: 'bar',
          data: data.lj_by,
          label: {
            show: true,
            position: 'top'
          },
          itemStyle: {
            color:'#3BC7DB'
          }
        },
        {
          name: data.title1[1],
          type: 'bar',
          data: data.last_lj_by,
          label: {
            show: true,
            position: 'top'
          },
          itemStyle: {
            color:'#FF017E'
          }
        }
      ]
      var option = this.echartsMethod(titleText, xAxisData, seriesData, "月份", ['#ff007e', '#ff88b9'])
      myChart.setOption(option)
    },
    getBCustomerLsMethod(data) {
      let myChart = echarts.init(this.$refs.chart16);
      var titleText = [
        '{a|B类客户:}{b|累计流失人数分析}',
        ''
      ].join('\n');
      var xAxisData = data.x;
      var seriesData = [
        {
          name: data.title2[0],
          type: 'bar',
          data: data.lj_ls,
          label: {
            show: true,
            position: 'top'
          },
          itemStyle: {
            color:'#3BC7DB'
          }
        },
        {
          name: data.title2[1],
          type: 'bar',
          data: data.last_lj_ls,
          label: {
            show: true,
            position: 'top'
          },
          itemStyle: {
            color:'#FF017E'
          }
        }
      ]
      var option = this.echartsMethod(titleText, xAxisData, seriesData, "月份", ['#00c2a0', '#a9f2ba'])
      myChart.setOption(option)
    },
    getBCustomerZqxByMethod(data) {
      let myChart = echarts.init(this.$refs.chart22);
      var titleText = [
        '{a|B类客户:}{b|周期性保有人数分析}',
        ''
      ].join('\n');
      var xAxisData = data.x;
      var seriesData = [
        {
          name: data.title1[0],
          type: 'bar',
          data: data.zqx_by,
          label: {
            show: true,
            position: 'top'
          },
          itemStyle: {
            color:'#3BC7DB'
          }
        },
        {
          name: data.title1[1],
          type: 'bar',
          data: data.last_zqx_by,
          label: {
            show: true,
            position: 'top'
          },
          itemStyle: {
            color:'#FF017E'
          }
        }
      ]
      var option = this.echartsMethod(titleText, xAxisData, seriesData, "月份", ['#ff007e', '#ff88b9'])
      myChart.setOption(option)
    },
    getBCustomerZqxLsMethod(data) {
      let myChart = echarts.init(this.$refs.chart23);
      var titleText = [
        '{a|B类客户:}{b|周期性流失人数分析}',
        ''
      ].join('\n');
      var xAxisData = data.x;
      var seriesData = [
        {
          name: data.title2[0],
          type: 'bar',
          data: data.zqx_ls,
          label: {
            show: true,
            position: 'top'
          },
          itemStyle: {
            color:'#3BC7DB'
          }
        },
        {
          name: data.title2[1],
          type: 'bar',
          data: data.last_zqx_ls,
          label: {
            show: true,
            position: 'top'
          },
          itemStyle: {
            color:'#FF017E'
          }
        }
      ]
      var option = this.echartsMethod(titleText, xAxisData, seriesData, "月份", ['#00c2a0', '#a9f2ba'])
      myChart.setOption(option)
    },
    //第三页图形展示
    getThirdChartData(data) {
      if (data != null) {
        let myChart = echarts.init(this.$refs.thirdChart1);
        let myChart2 = echarts.init(this.$refs.thirdChart2);
        let titleText = [
          '{a|员工总业绩}',
          ''
        ].join('\n');
        let titleText2 = [
          '{a|每日护理客户数与护理量对比图}',
          ''
        ].join('\n');
        let xAxisData = data.x;
        let seriesData = [
          {
            type: 'bar',
            barWidth: '50%',
            data: data.performance,
            label: {
              show: true,
              position: 'top'
            }
          }
        ];
        let seriesData2 = [
          {
            name: data.title[2],
            type: 'bar',
            data: data.hll,
            label: {
              show: true,
              position: 'top'
            },
            itemStyle: {
              color:'#3BC7DB'
            }
          },
          {
            name: data.title[1],
            type: 'bar',
            data: data.hls,
            label: {
              show: true,
              position: 'top'
            },
            itemStyle: {
              color:'#3BC7DB'
            }
          },
          {
            name: data.title[0],
            type: 'bar',
            data: data.hlzs,
            label: {
              show: true,
              position: 'top'
            },
            itemStyle: {
              color:'#FF017E'
            }
          }
        ]
        let option = this.echartsMethod(titleText, xAxisData, seriesData, "月份", ["#0055ff"])
        myChart.setOption(option)
        let option2 = this.echartsMethod(titleText2, xAxisData, seriesData2, "月份")
        myChart2.setOption(option2)
      }
    },
    // 获取数据
    getList(option) {
      readAdminSysHomeInfo({ ...this.search }).then(res => {
        // this.echarts1(res.sysFinishEnterStoreEcharts);
        // let cominInData = res.sysFinishEnterStoreEcharts;
        this.dataReveal.revealList[0].num = res.sysCurrentPerformance;
        this.dataReveal.revealList[1].num = res.sysCompleteNewOrder;
        this.dataReveal.revealList[2].num = res.sysCompleteSupplement;
        this.dataReveal.revealList[3].num = res.sysNewOrderTurnoverRate;
        this.dataReveal.revealList[4].num = res.sysDealNumber;
        this.dataReveal.revealList[5].num = res.sysFinishEnterStore;
        this.dataReveal.revealList[6].num = res.sysOnlineStoreEntryRate;
        this.dataReveal.revealList[7].num = res.referralsCout;
        this.dataReveal.revealList2[0].num = res.customerCJNumber;   /*  成交客户*/
        this.dataReveal.revealList2[1].num = res.customerTenureNumber;  /*  保有客户*/
        this.dataReveal.revealList2[2].num = res.customerDrainNumber;  /*  流失客户*/
        this.dataReveal.revealList2[3].num = res.customerWarnNumber;  /*  预警客户*/
        this.dataReveal.revealList2[4].num = res.customerRetentionRate;   /*  保有率*/
        this.dataReveal.revealList2[5].num = res.back_warn_Rate;  /*  回流率*/

        this.dataReveal.revealList[0].content = '订单完成时间在统计时间内，店面产生的所有业绩合计';
        this.dataReveal.revealList[1].content = '业务日期在统计时间内，所有订单类型为新单的收款合计(收款单合计减去退款单合计)';
        this.dataReveal.revealList[2].content = '业务日期在统计时间内，所有订单类型为补单的收款合计(收款单合计减去退款单合计)';
        this.dataReveal.revealList[3].content = '统计时间内，成交顾客人数占总进店人数的比率';
        this.dataReveal.revealList[4].content = '统计时间内，进店且首次成交500以上的新单人数统计';
        this.dataReveal.revealList[5].content = '首次进店或者二次进店在统计时间内的人数统计';
        this.dataReveal.revealList[6].content = '线上进店人数占线上档案人数的比率';
        this.dataReveal.revealList[7].content = '来源是转介绍且进店的顾客人数';
      });
      readAdminSysHomePersonAchieve({ ...this.search }).then(res => {
        this.dataReveal.revealList3 = res.list;
      });
    },

    // 快捷导航
    tapBtn(routerName) {
      this.$router.push({ name: routerName });
    },
    echartsMethod(title, xAxisData, seriesData, xAxisName = '日期', color = ['#ff007e', '#00c2a0']) {
      return {
        color: color,
        title: {
          // text: '进店率年度对比',
          width: 500,
          text: [
            title,
            ''
          ].join('\n'),
          textStyle: {
            rich: {
              a: {
                color: '#0055ff',
                fontSize: 16
              },
              b: {
                color: '#5c6580',
                fontSize: 16
              },
              c: {
                color: '#5c6580',
                fontSize: 16
              }
            }
          }
        },
        tooltip: {
          trigger: 'axis',
        },
        legend: {
          right: 0,
        },
        toolbox: {
          show: true,
        },
        calculable: true,
        xAxis: {
          type: 'category',
          name: xAxisName,
          nameLocation: 'end',
          data: xAxisData
        }
        ,
        yAxis: {
          type: 'value'
        },
        series: seriesData
      }
    },
  }
};
</script>

<style lang='scss' scoped>
.echart {
  width: 100%;
  height: 1600px;
}

.bg {
  width: 100%;
  height: 100%;
  filter: blur(2px);
  position: fixed;
  background-image: url(../../assets/images/banr.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.echart_box {
  left: 0;
  top: 0;
  padding: 20px;
}

.title {
  font-size: 18px;
  color: #0055ff;
  padding: 10px 20px;
}

.tableLists {
  display: flex;
  justify-content: space-between;
}

.tableLists>div {
  width: 30%;
}

.search {
  @include successBtn(70px);
}

.radiox {
  display: block;
}

.mainColor {
  color: #2dc0a3;
}

.el-form-item {
  margin-bottom: 0;

  ::v-deep .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 160px;
  }
}

.warn {
  padding-right: 30px;
  height: 34px;
  margin-left: 50px;
  margin-right: 20px;
}

.boxBottom {
  border-bottom: 6px solid #f6f6f6;
}

.dataBox {
  display: flex;
  justify-content: space-between;

  .Box {

    background-color: #fff;
    border-radius: 4px;
    opacity: 0.8;
  }

  .left {
    margin-right: 20px;
    width: 70%;

    .jbList {
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: center;

      height: 140px;
      margin: 20px;
      border-radius: 4px;

      .jbList_yeji_warp {
        margin-top: 40px;
        font-size: 18px;
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        >div {
          width: 50%;

          .tit {
            font-size: 16px;
            font-weight: 500;
            color: #0055ff;
            border-bottom: 1px solid #0055ff;
            display: inline-block;
            margin-bottom: 0;
          }

          .info {
            font-size: 30px;
            color: #0055ff;
            margin: 10px 0;
            font-weight: 700;
          }

          .dw {
            font-size: 14px;
            font-style: 16px;
            color: #000;
          }
        }
      }

      >div {
        margin: 0 10px;


        .tit {
          font-size: 18px;
          font-weight: 500;
          color: #0055ff;
          border-bottom: 1px solid #0055ff;
          display: inline-block;
          margin-bottom: 0;


        }

        .info {
          font-size: 38px;
          color: #0055ff;
          margin: 10px 0;
          font-weight: 700;
        }

        .dw {
          font-style: 16px;
          color: #000;
        }
      }

    }
  }

  .right {
    color: #0055ff;
    width: 30%;

    .jb {
      font-size: 25px;
      font-weight: 600;
      color: #0055ff;
      margin-top: 50px;

      span {
        font-size: 44px;
      }
    }

    .jb,
    .num {
      text-align: center;
      padding: 10px 0;
    }

    .num {
      font-size: 16px;
    }

    .jbList {
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: center;
      background-image: linear-gradient(to bottom, #6490ed, #84abfe);
      height: 140px;
      margin: 20px;
      border-radius: 4px;

      div {
        margin: 0 10px;
        color: #fff;

        .tit {
          font-size: 18px;
          font-weight: 500;

          span {
            font-size: 22px;
          }
        }

        .info {
          font-size: 14px;
        }
      }

    }
  }

  .dataReveal {
    width: 25%;
    padding: 24px 0px;
    display: flex;
    text-align: center;

    img {
      width: 50px;
      height: 50px;
      margin-right: 20px;
    }

    .reveal {
      border-right: 1px solid #ddd;
      width: 100%;

      .revealText {
        font-size: 14px;
        color: #999;
      }

      .revealNum {
        text-align: center;
        color: #665f59;
        font-size: 22px;
        font-weight: bold;
        margin-top: 10px;
      }
    }
  }

  .dataReveal:nth-child(4n) .reveal {
    border-right: 0;
  }

  .rapid {
    width: 10%;
    padding: 14px 20px;
    display: flex;
    text-align: center;
    background: #eee;
    border-radius: 30px;
    color: #fff;
    cursor: pointer;
    font-weight: bold;
    letter-spacing: 4px;
    margin: 0 60px 20px 60px;
  }

  .rapid:hover {
    box-shadow: 0px 0px 3px #bbb;
  }

  .rapid:nth-child(1) {
    color: #cc663e;
    // background: #ebbcaa;
    background: linear-gradient(to bottom right, #ebbcaa, #ebbcaa70);
  }

  .rapid:nth-child(2) {
    // background: #b9b3d9;
    color: #7265bb;
    background: linear-gradient(to bottom right, #b9b3d9, #b9b3d970);
  }

  .rapid:nth-child(3) {
    // background: #f6dfad;
    color: #ddb153;
    background: linear-gradient(to bottom right, #f6dfad, #f6dfad70);
  }

  .rapid:nth-child(4) {
    // background: #c3e1d5;
    color: #51c999;
    background: linear-gradient(to bottom right, #c3e1d5, #c3e1d570);
  }

  .rapid:nth-child(5) {
    // background: #f0cceb;
    color: #e77ad9;
    background: linear-gradient(to bottom right, #f0cceb, #f0cceb70);
  }

  .rapid:nth-child(6) {
    // background: #dae0be;
    color: #a0b34f;
    background: linear-gradient(to bottom right, #dae0be, #dae0be70);
  }

  .rapid:nth-child(7) {
    // background: #b0ebc6;
    color: #6bb988;
    background: linear-gradient(to bottom right, #b0ebc6, #b0ebc670);
  }

  .rapid:nth-child(8) {
    // background: #CEDEF7;
    color: rgb(134, 164, 212);
    background: linear-gradient(to bottom right, #cedef7, #cedef770);
  }

  .revealText {
    width: 100%;
  }
}

.modules {
  background: #fff;
  margin-top: 10px;

  .modulesTel {
    padding: 6px 0px 20px;
    color: #665f59;
    font-size: 14px;
    font-weight: bold;
  }

  .modulesCon {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .itemCon {
      width: 30%;
      padding: 10px;
      font-size: 14px;
      color: #878683;

      div {
        text-align: center;
      }

      .itemConNum {
        color: #665f59;
        font-size: 22px;
        font-weight: bold;
        margin-bottom: 10px;
        padding-left: 20px;
      }

      img {
        width: 18px;
        height: 18px;
        vertical-align: bottom;
        margin-right: 6px;
      }

      span {
        color: #f15956;
      }
    }

    .cursor {
      cursor: pointer;
    }
  }
}

.modules:last-child {
  margin-bottom: 0;
}

.client {
  padding: 10px 0;
  border-top: 6px solid #f6f6f6;
  border-bottom: 6px solid #f6f6f6;
  // border-radius: 6px;
}

::v-deep .el-notification__group {
  cursor: pointer !important;
}

.echartsBox {
  width: 50%;
  height: 360px;

}

.echartsBoxOne {
  width: 100%;
  height: 360px;
}

::v-deep .el-tabs__header {
  width: 100%;
  height: 50px;
  line-height: 50px;

  background-color: #fff;
}

.plborder {
  padding-right: 10px;
  border-right: 1px solid #bdd3fe;
}

/* 去掉长分割线 */
::v-deep .el-tabs__nav-wrap::after {
  position: static !important;
  height: 0px !important;
}

/* 去掉下划线 */
::v-deep .el-tabs__active-bar {
  background-color: transparent !important;
}

::v-deep .el-tabs__nav {
  padding: 2px 20px;
}

::v-deep .el-tabs__header {
  border-radius: 4px;
  margin-bottom: 5px !important;
}

::v-deep .el-tabs__item.is-active {
  color: #0055ff;
}



::v-deep .el-tabs__item:hover {
  color: #0055ff;
}

::v-deep .no-border-table .el-table__row {
  border-bottom: none;
}

::v-deep.no-border-table .el-table__header-wrapper {
  border-bottom: none;
}

::v-deep .borr {
  border-right: 1px solid #5c6580;
}

.trapezoid {
  height: 350px;

  .trapezoidWarp {
    width: 375px;
    margin: 0 auto;
    position: relative;

    >div {
      height: 66px;
      margin: 10px auto;
      color: #fff;
      font-size: 20px;
      line-height: 60px;
      border-radius: 10px;
      text-align: center;
    }

    >div.position {
      color: #333;
    }

    .trapezoidItem1 {
      width: 374px;
      background: url('../../assets/images/tixing1.png') no-repeat center;
      background-size: auto;
    }

    .trapezoidItem2 {
      width: 318px;
      background: url('../../assets/images/tixing2.png') no-repeat center;
      background-size: auto;
    }

    .trapezoidItem3 {
      width: 260px;
      background: url('../../assets/images/tixing3.png') no-repeat center;
      background-size: auto;
    }

    .trapezoidItem4 {
      width: 200px;
      background: url('../../assets/images/tixing4.png') no-repeat center;
      background-size: auto;
    }

    .trapezoidItem5 {

      color: #6d6229;
      margin: -30px;
    }
  }

  .position {
    height: 86px;
    display: flex;
    justify-content: end;
    align-items: center;
    position: absolute;
    font-size: 16px;

    .color-red {
      color: #FE0C82;
    }

    .color-green {
      color: #0EC5A5;
    }
  }

  .trapezoidLeft {
    justify-content: end;
    width: 300px;
    text-align: right;
    top: 105px;
    left: -225px;
  }

  .trapezoidRight1 {
    justify-content: start;
    width: 300px;
    text-align: left;
    top: 30px;
    right: -255px;
  }

  .trapezoidRight2 {
    justify-content: start;
    width: 300px;
    text-align: left;
    top: 220px;
    right: -200px;
  }
}
</style>
