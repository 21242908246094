import { render, staticRenderFns } from "./digital-signage.vue?vue&type=template&id=33c6d586&scoped=true&"
import script from "./digital-signage.vue?vue&type=script&lang=js&"
export * from "./digital-signage.vue?vue&type=script&lang=js&"
import style0 from "./digital-signage.vue?vue&type=style&index=0&id=33c6d586&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33c6d586",
  null
  
)

export default component.exports